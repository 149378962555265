import redirectQuery from '@gql/queries/redirectQuery';

export default async function () {
    const { href, pathname, hostname } = useRequestURL();
    const query = redirectQuery();
    const variables = { url: href };

    let redirectData = null;
    // This is a special case where we want to redirect to the home page
    // but I think this is always handled by ALB
    if (pathname === '/' || pathname === '') {
        redirectData = {
            path: '/',
            locale: 'en',
            center: 'global',
        };
    } else {
        const response = await useAsyncQuery(query, variables);
        writeLastQuery(query, variables, 'redirectQuery');

        redirectData = response?.data?.value?.redirect;
    }

    let route = null;

    if (redirectData.path || hostname !== import.meta.env.VITE_PRIMARY_DOMAIN) {
        const { path, locale, center } = redirectData;

        // If the redirect path is not defined, redirect to primary domain but with the original path
        const newPath = path || pathname;
        route = {
            path: `${process.client ? window.location.protocol + '//' : 'https://'}${
                import.meta.env.VITE_PRIMARY_DOMAIN
            }${newPath}`,
            query: {
                lang: locale,
                center,
            },
        };
    }

    if (route?.path) {
        console.log(`redirecting from ${pathname} to ${route.path}`);
    }
    return route;
}
